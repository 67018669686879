define("discourse/plugins/discourse-whos-online/discourse/components/whos-online-avatar", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "a",
    attributeBindings: ["user.username:data-user-card", "user.path:href"]
  });
});