define("discourse/plugins/discourse-whos-online/discourse/components/whos-online", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _component, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    online: (0, _service.inject)("online-service"),
    showWhosOnline() {
      // If the number of users is less than the minimum, and it's set to hide, hide it
      if (this.online.users.length < this.siteSettings.whos_online_minimum_display && this.siteSettings.whos_online_hide_below_minimum_display) {
        return false;
      }
      return this.online.shouldDisplay;
    },
    users() {
      return this.online.users.slice(0, this.siteSettings.whos_online_maximum_display);
    },
    isLong() {
      return this.online.users.length >= this.siteSettings.whos_online_collapse_threshold;
    },
    isUsers() {
      return this.online.users.length >= this.siteSettings.whos_online_minimum_display;
    }
  }, [["method", "showWhosOnline", [(0, _decorators.default)("online.users.length")]], ["method", "users", [(0, _decorators.default)("online.users.@each")]], ["method", "isLong", [(0, _decorators.default)("online.users.length")]], ["method", "isUsers", [(0, _decorators.default)("online.users.length")]]]));
});