define("discourse/plugins/discourse-whos-online/discourse/initializers/start-whos-online", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _pluginApi, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "whos-online";
  var _default = _exports.default = {
    name: "start-whos-online",
    initialize(container) {
      const onlineService = container.lookup("service:online-service");
      const siteSettings = container.lookup("site-settings:main");

      // If user not allowed, don't display
      if (!onlineService.get("shouldDisplay")) {
        return;
      }
      const indicatorType = siteSettings.whos_online_avatar_indicator;

      // If feature disabled, don't display
      if (indicatorType === "none") {
        return;
      }

      // Set the html class accordingly
      $("html").addClass(`whos-online-${indicatorType}`);
      (0, _pluginApi.withPluginApi)("0.2", api => {
        api.modifyClass("component:user-card-contents", dt7948.p({
          pluginId: PLUGIN_ID,
          onlineService: (0, _service.inject)("online-service"),
          classNameBindings: ["isOnline:user-online"],
          isOnline(user) {
            if (!user) {
              return false;
            }
            return this.onlineService.isUserOnline(user.id);
          }
        }, [["method", "isOnline", [(0, _decorators.default)("user", "onlineService.users.@each")]]]));

        // This is a bit hacky, since the user page doesn't currently
        // use components
        api.modifyClass("route:user", dt7948.p({
          pluginId: PLUGIN_ID,
          onlineService: (0, _service.inject)("online-service"),
          afterModel() {
            this.updateBodyClass();
            return this._super();
          },
          updateBodyClass() {
            const user_id = this.modelFor("user").id;
            const isOnline = this.get("onlineService").isUserOnline(user_id);
            if (isOnline) {
              Ember.$("body").addClass("user-page-online");
            } else {
              Ember.$("body").removeClass("user-page-online");
            }
          },
          deactivate() {
            this._super();
            Ember.$("body").removeClass("user-page-online");
          }
        }, [["method", "updateBodyClass", [(0, _decorators.observes)("onlineService.users.@each")]]]));
        if (siteSettings.whos_online_avatar_indicator_topic_lists) {
          api.modifyClass("component:topic-list-item", dt7948.p({
            pluginId: PLUGIN_ID,
            onlineService: (0, _service.inject)("online-service"),
            classNameBindings: ["isOnline:last-poster-online"],
            isOnline(lastPosterId, lastPosterUserId) {
              return this.get("onlineService").isUserOnline(lastPosterId || lastPosterUserId);
            }
          }, [["method", "isOnline", [(0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "onlineService.users.@each")]]]));
          api.modifyClass("component:latest-topic-list-item", dt7948.p({
            pluginId: PLUGIN_ID,
            onlineService: (0, _service.inject)("online-service"),
            classNameBindings: ["isOnline:last-poster-online"],
            isOnline(lastPosterId, lastPosterUserId) {
              return this.get("onlineService").isUserOnline(lastPosterId || lastPosterUserId);
            }
          }, [["method", "isOnline", [(0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "onlineService.users.@each")]]]));
        }
        api.modifyClass("component:scrolling-post-stream", {
          pluginId: PLUGIN_ID,
          didInsertElement() {
            this._super();
            this.appEvents.on("whosonline:changed", changedUserIds => {
              changedUserIds.forEach(id => {
                let postIds = this.get("attrs").posts.value.posts.filter(_ref => {
                  let {
                    user_id
                  } = _ref;
                  return user_id === id;
                }).map(post => post.id);
                postIds.forEach(postId => {
                  this.dirtyKeys.keyDirty(`post-${postId}`);
                  this.dirtyKeys.keyDirty(`post-${postId}-avatar-${id}`, {
                    onRefresh: "updateOnline"
                  });
                });
              });
              this.queueRerender();
            });
          },
          willDestroyElement() {
            this.appEvents.off("whosonline:changed");
          }
        });
        api.reopenWidget("post-avatar", {
          buildKey: attrs => `post-${attrs.id}-avatar-${attrs.user_id}`,
          defaultState(attrs) {
            return {
              online: onlineService.isUserOnline(attrs.user_id)
            };
          },
          updateOnline() {
            this.state.online = onlineService.isUserOnline(this.attrs.user_id);
          },
          buildClasses(attrs, state) {
            if (state.online) {
              return "user-online";
            }
            return [];
          }
        });
      });
    }
  };
});